let resumeData = {
    "imagebaseurl":"http://localhost:3000/",
    "name": "marek tomas",
    "role": "more than 7 years of experience from various projects in Java programming and development. Full stack coding orientation preferring back-end.",
    "description": "This is me.",
    "street": "Gessayova 37",
    "city": "Bratislava",
    "email": "marektomas.mt@gmail.com",
    "keys": "_coding"
}
export default resumeData