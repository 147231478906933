import React, {Component} from 'react';

export default class Hobby extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="hobby">
                    <div className="row">
                        <div className="twelve columns collapsed">
                            <h1>Some of my interests</h1>
                            {/* hobby-wrapper */}
                            <div id="hobby-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                                <div className="columns hobby-item">
                                    <div className="item-wrap">
                                        <a href="#modal-01" title>
                                            <img alt src="images/hobby/coffee.jpg"/>
                                            <div className="overlay">
                                                <div className="hobby-item-meta">
                                                    <h5>Coffee</h5>
                                                    <p>Hobby</p>
                                                </div>
                                            </div>
                                            <div className="link-icon"><i className="icon-plus"/></div>
                                        </a>
                                    </div>
                                </div>
                                {/* item end */}
                                <div className="columns hobby-item">
                                    <div className="item-wrap">
                                        <a href="#modal-02" title>
                                            <img alt src="images/hobby/into-the-light.jpg"/>
                                            <div className="overlay">
                                                <div className="hobby-item-meta">
                                                    <h5>Photography</h5>
                                                    <p>Hobby</p>
                                                </div>
                                            </div>
                                            <div className="link-icon"><i className="icon-plus"/></div>
                                        </a>
                                    </div>
                                </div>
                                {/* item end */}
                                <div className="columns hobby-item">
                                    <div className="item-wrap">
                                        <a href="#modal-03" title>
                                            <img alt src="images/hobby/car.jpg"/>
                                            <div className="overlay">
                                                <div className="hobby-item-meta">
                                                    <h5>Driving car</h5>
                                                    <p>Hobby</p>
                                                </div>
                                            </div>
                                            <div className="link-icon"><i className="icon-plus"/></div>
                                        </a>
                                    </div>
                                </div>
                                {/* item end */}
                                <div className="columns hobby-item">
                                    <div className="item-wrap">
                                        <a href="#modal-04" title>
                                            <img alt src="images/hobby/camera.jpg"/>
                                            <div className="overlay">
                                                <div className="hobby-item-meta">
                                                    <h5>Photo camera</h5>
                                                    <p>Hobby</p>
                                                </div>
                                            </div>
                                            <div className="link-icon"><i className="icon-plus"/></div>
                                        </a>
                                    </div>
                                </div>
                                {/* item end */}
                                <div className="columns hobby-item">
                                    <div className="item-wrap">
                                        <a href="#modal-05" title>
                                            <img alt src="images/hobby/teo.jpg"/>
                                            <div className="overlay">
                                                <div className="hobby-item-meta">
                                                    <h5>My litle boy</h5>
                                                    <p>Love</p>
                                                </div>
                                            </div>
                                            <div className="link-icon"><i className="icon-plus"/></div>
                                        </a>
                                    </div>
                                </div>
                                <div className="columns hobby-item">
                                    <div className="item-wrap">
                                        <a href="#modal-06" title>
                                            <img alt src="images/hobby/oscar-teo.jpg"/>
                                            <div className="overlay">
                                                <div className="hobby-item-meta">
                                                    <h5>My litle boys</h5>
                                                    <p>Love</p>
                                                </div>
                                            </div>
                                            <div className="link-icon"><i className="icon-plus"/></div>
                                        </a>
                                    </div>
                                </div>
                                {/* item end */}
                                <div className="columns hobby-item">
                                    <div className="item-wrap">
                                        <a href="#modal-07" title>
                                            <img alt src="images/hobby/mountain.jpg"/>
                                            <div className="overlay">
                                                <div className="hobby-item-meta">
                                                    <h5>Best activity</h5>
                                                    <p>Mountain walking</p>
                                                </div>
                                            </div>
                                            <div className="link-icon"><i className="icon-plus"/></div>
                                        </a>
                                    </div>
                                </div>
                                {/* item end */}</div>
                            {/* hobby-wrapper end */}
                        </div>
                        {/* twelve columns end */}
                        {/* Modal Popup
	      --------------------------------------------------------------- */}
                        <div id="modal-01" className="popup-modal mfp-hide">
                            <img  src="images/hobby/modals/m-coffee.jpg" alt/>
                            <div className="description-box">
                                <h4>I like Coffee</h4>
                                <span className="categories"><i className="fa fa-tag"/>Interest</span>
                            </div>
                            <div className="link-box">
                                {/*<a href="#">Details</a>*/}
                                <a className="popup-modal-dismiss">Close</a>
                            </div>
                        </div>
                        {/* modal-01 End */}
                        <div id="modal-02" className="popup-modal mfp-hide">
                            <img  src="images/hobby/modals/m-intothelight.jpg" alt/>
                            <div className="description-box">
                                <h4>Photography</h4>
                                <span className="categories"><i className="fa fa-tag"/>Hobby</span>
                            </div>
                            <div className="link-box">
                                {/*<a href="">Details</a>*/}
                                <a className="popup-modal-dismiss">Close</a>
                            </div>
                        </div>
                        <div id="modal-03" className="popup-modal mfp-hide">
                            <img  src="images/hobby/modals/m-car.jpg" alt/>
                            <div className="description-box">
                                <h4>Car</h4>
                                <span className="categories"><i className="fa fa-tag"/>Interest</span>
                            </div>
                            <div className="link-box">
                                {/*<a href="">Details</a>*/}
                                <a className="popup-modal-dismiss">Close</a>
                            </div>
                        </div>
                        <div id="modal-04" className="popup-modal mfp-hide">
                            <img  src="images/hobby/modals/m-camera.jpg" alt/>
                            <div className="description-box">
                                <h4>Photo camera</h4>
                                <span className="categories"><i className="fa fa-tag"/>Interest</span>
                            </div>
                            <div className="link-box">
                                {/*<a href="">Details</a>*/}
                                <a className="popup-modal-dismiss">Close</a>
                            </div>
                        </div>
                        <div id="modal-05" className="popup-modal mfp-hide">
                            <img  src="images/hobby/modals/m-teo.jpg" alt/>
                            <div className="description-box">
                                <h4>Teo</h4>
                                <span className="categories"><i className="fa fa-tag"/>Hobby</span>
                            </div>
                            <div className="link-box">
                                {/*<a href="">Details</a>*/}
                                <a className="popup-modal-dismiss">Close</a>
                            </div>
                        </div>
                        <div id="modal-06" className="popup-modal mfp-hide">
                            <img  src="images/hobby/modals/m-oscar-teo.jpg" alt/>
                            <div className="description-box">
                                <h4>Oscar and Teo</h4>
                                <span className="categories"><i className="fa fa-tag"/>Hobby</span>
                            </div>
                            <div className="link-box">
                                {/*<a href="">Details</a>*/}
                                <a className="popup-modal-dismiss">Close</a>
                            </div>
                        </div>

                        <div id="modal-07" className="popup-modal mfp-hide">
                            <img  src="images/hobby/modals/m-mountain.jpg" alt/>
                            <div className="description-box">
                                <h4>High Tatras</h4>
                                <span className="categories"><i className="fa fa-tag"/>Hobby</span>
                            </div>
                            <div className="link-box">
                                {/*<a href="">Details</a>*/}
                                <a className="popup-modal-dismiss">Close</a>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <h1>Some links</h1>
                        <div className="columns main-col">
                            <div className="bgrid-halves s-bgrid-halves cf">

                                <div className="columns hobby-item">
                                        <a href="http://www.akosamatlepsie.sk" target="_blank">
                                            <div id="modal-08">
                                                <img src="images/ako-sa-mat-lepsie.jpg" alt/>
                                            </div>
                                        </a>
                                </div>

                                <div className="columns hobby-item">
                                        <a href="https://www.bratislavapsycholog.sk/" target="_blank">
                                            <div id="modal-09">
                                                <img src="images/ba-psycholog.jpg" alt/>
                                            </div>
                                        </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}