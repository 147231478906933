import React, {Component} from 'react';

export default class Experience extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="experience">
                    <div className="row work">
                        <div className="three columns header-col">
                            <h1><span><experience>My Experience</experience></span></h1>
                        </div>
                        <div className="nine columns main-col">
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>Main key words</h3>
                                    <p className="info">
                                        <b>OS: Linux (prefer)</b>, macOS, Windows (active)<br/><br/>
                                        <b>Languages: Java</b> (very good knowledge-vgk), <b>J2EE</b>(good knowledge-gk), <b>SpringBoot</b>(gk), <b>Angular 11</b>(gk), <b>TS</b> and <b>RxJS</b> - store, actions, effects, Observable (gk), <b>HTML</b> - components (vgk), Vaadin (gk), JavaScript (gk), Scala (gk), Groovy (gk), Python (basic knowledge-bk), Perl (bk)<br/><br/>
                                        <b>DB: PostgreSQL (gk)</b>, Oracle (gk), <b>MongoDb (gk), Redis (gk)</b>, MySQL (gk), Liquibase (gk)<br/><br/>
                                        <b>ORM: Hibernate (gk), EclipseLink (gk)</b><br/><br/>
                                        <b>Others keywords: GlassFish, Tomcat, Jenkins, Bamboo, BitBucket, Jira, Maven</b>, JSON, XML, jQuery, Css, Raml, yaml <b>Git</b>, IntelliJ <b>IDEA</b>, <b>Stream API, Postman</b>, Kibana, ElasticSearch, <b>REST</b> API, ScriptRunner<br/><br/>
                                    </p>
                                </div>
                            </div>
                            <div className="row item">
                                <div className="twelve columns">
                                    <h3>Java Developer in more projects</h3>
                                    <p className="info">
                                        Actual position in Java small fresh team. Project on <b>"green meadow"</b>, nice technologic stack from BE ( SpringBoot, Java 11, PostgreSql, Redis, Hibernate, REST api, ... ) to FE (Angular 11, TS, RxJS, store, actions, effects, Observable, HTML, custom components, UX)
                                        <br/><br/>
                                        Programming in java, small and scrum team (4 developers), daily StandUp, JIRA issues workflow, story tasks. Run in docker, JPA EclipseLink PostrgreSql, Redis, Mongo, REST api, Glassfish. Git flow, review code in Gitlab
                                        <br/><br/>
                                        Developer in Atlassian team: JIRA projects, JIRA plugins, admin in  JIRA system, workflows, Atlassian sdk plugins in Java, Groovy,  Maven, Git, BitBucket, Velocity, Bootstrap, jQuery, Scala, Python  Administrator: Upgrades and installing JIRA, Confluence
                                        <br/><br/>
                                        Programming in J2EE cloud application, Vaadin, Liferay, Hibernate, Oracle, svn/Git, Maven, Tomcat, JIRA
                                        <br/><br/>
                                        Programming in J2EE, Spring Web MVC model, Hibernate, JSP, DB informix, IBM WebSphere, IBM clearCase, IBM clearQuest, JIRA
                                        <br/><br/>
                                        Programming in Java/J2EE, EJB, Git, Hibernate, IBM WebSphere
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row education">
                        <div className="three columns header-col">
                            <h1><span>Education</span></h1>
                        </div>
                        <div className="nine columns main-col">
                            <div className="row item">
                                <div className="twelve columns">
                                    <h4>Masaryk University/ Faculty of Informatics, Brno, (Mgr.)</h4>
                                    <p className="info">Informatics systems <span>•</span> <em className="date">
                                        2009 - 2013</em></p>
                                </div>
                            </div>
                            <div className="row item">
                                <div className="twelve columns">
                                    <h5>UCM/ Faculty of Natural Sciences, Trnava, (Bc)</h5>
                                    <p className="info">Informatics <span>•</span> <em
                                        className="date">2005 - 2009</em></p>
                                </div>
                            </div>
                            <div className="row item">
                                <div className="twelve columns">
                                    <h5>Secondary School, Levice</h5>
                                    <p className="info">Technical Information Services <span>•</span> <em
                                        className="date">2000 - 2005</em></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

            </React.Fragment>
        );
    }
}