import React, { Component } from 'react';
export default class Ideas extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="ideas">
                    <div className="text-container">
                        <div className="row">
                            <div className="four columns header-col">
                                <h2><span>My ideas</span></h2>
                            </div>
                            <div className="twelve columns flex-container">
                                <div className="flexslider">
                                    <ul className="slides">
                                        <li>
                                            <blockquote>
                                                <p>From zero to hero<br/>
                                                Every experience moves me a step forward</p>
                                                <cite>mt</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>Life write the most beautiful stories</p>
                                                <cite>life</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                        <li>
                                            <blockquote>
                                                <p>Free Time</p>
                                                <cite>freedom</cite>
                                            </blockquote>
                                        </li> {/* slide ends */}
                                    </ul>
                                </div> {/* div.flexslider ends */}
                            </div> {/* div.flex-container ends */}
                        </div> {/* row ends */}
                    </div>  {/* text-container ends */}
                </section>

            </React.Fragment>
        );
    }
}