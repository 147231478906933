import React, { Component } from 'react';
export default class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="row">
                        <div className="twelve columns">
                            <ul className="social-links">
                                <li><a href="https://www.facebook.com/marektomas.mt" target="_blank">
                                    <i className="fa fa-facebook" /></a></li>
                                <li><a href="https://www.linkedin.com/in/marek-tom%C3%A1%C5%A1-23a80776" target="_blank">
                                    <i className="fa fa-linkedin" /></a></li>
                                <li><a href="https://github.com/sogysvk" target="_blank">
                                    <i className="fa fa-github" /></a></li>
                                <li><a href="#"><i className="fa fa-skype" /></a></li>
                            </ul>
                            <ul className="copyright">
                                <li>© Copyright 2019 MT</li>
                                <li>Design by <a title="ReactJS" href="https://reactjs.org/" target="_blank">ReactJS</a></li>
                                <li>marek tomas</li>
                            </ul>
                        </div>
                        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open" /></a></div>
                    </div>
                </footer>

            </React.Fragment>
        );
    }
}