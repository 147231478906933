import React, {Component} from 'react';
import resumeData from "../../resumeData";

export default class About extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="about">
                    <div className="row">
                        <div className="two columns">
                            <img className="profile-pic" src="images/me.jpg" alt/>
                        </div>
                        <div className="nine columns main-col">
                            <h2>About Me</h2>
                            <p>
                                <span>Hi everybody</span>,
                                so my name is <span>marek</span>, I'm glad you visited my <span>website</span>.
                                It's a place where you can get to know my <span>interests</span>, work <span>experience</span>, hobby but also little of my <span>family</span> :)
                            </p>
                            <div className="row">
                                <div className="columns contact-details">
                                    <h2>Contact Details</h2>
                                    <p className="address">
                                        {resumeData.name} <span>• </span>
                                        {resumeData.city} <span>• </span>
                                        {resumeData.email}
                                    </p>
                                </div>
                                {/*<div className="columns download">*/}
                                    {/*<p>*/}
                                        {/*<a href="#" className="button"><i className="fa fa-download"/> Download*/}
                                            {/*Experience</a>*/}
                                    {/*</p>*/}
                                {/*</div>*/}
                            </div>
                            {/* end row */}
                        </div>
                        {/* end .main-col */}
                    </div>
                </section>

            </React.Fragment>
        );
    }
}