import React, {Component} from 'react';
import resumeData from "../../resumeData";
import logo from '../../logo.svg';

export default class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="contact">
                    <div className="row section-head">
                        <div className="two columns header-col">
                            <h1><span>Get In Touch.</span></h1>
                        </div>
                        <div className="ten columns">
                            <p className="lead">
                                If you have any questions, send me message ...
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="six columns">
                            <form action="mailto:marektomas.mt@gmail.com" method="POST" target="_blank">
                                <fieldset>
                                    <div>
                                        <input name="subject" size="35" type="text" placeholder="Subject"/>
                                    </div>
                                </fieldset>
                                        <textarea name="body" placeholder="Body"/>
                                        <input type="submit" value="Send email" />
                            </form>
                             {/*form */}
                            {/*<form action method="post" id="contactForm" name="contactForm">*/}
                                {/*<fieldset>*/}
                                    {/*<div>*/}
                                        {/*<label htmlFor="contactName">Name <span className="required">*</span></label>*/}
                                        {/*<input type="text" size={35} id="contactName" name="contactName"*/}
                                        {/*placeholder="..."/>*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                        {/*<label htmlFor="contactEmail">Email <span className="required">*</span></label>*/}
                                        {/*<input type="text" size={35} id="contactEmail" name="contactEmail"*/}
                                        {/*placeholder="..."/>*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                        {/*<label htmlFor="contactSubject">Subject</label>*/}
                                        {/*<input type="text" size={35} id="contactSubject" name="contactSubject"*/}
                                               {/*placeholder="..."/>*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                        {/*<label htmlFor="contactMessage">Message <span*/}
                                            {/*className="required">*</span></label>*/}
                                        {/*<textarea cols={50} rows={15} id="contactMessage" name="contactMessage"*/}
                                        {/*placeholder="..."/>*/}
                                    {/*</div>*/}
                                    {/*<div>*/}
                                        {/*<button className="submit" id="buttonSummit">Submit</button>*/}
                                        {/*<span id="image-loader"><img alt src="images/loader.gif"/></span>*/}
                                    {/*</div>*/}
                                {/*</fieldset>*/}
                            {/*</form>*/}
                            {/* Form End */}
                            {/* contact-warning */}
                            {/*<div id="message-warning"> Error boy</div>*/}
                            {/*/!* contact-success *!/*/}
                            {/*<div id="message-success">*/}
                                {/*<i className="fa fa-check"/>Your message was sent, thank you!<br/>*/}
                            {/*</div>*/}
                        </div>
                        <aside className="five columns footer-widgets">
                            <div className="widget widget_contact">
                                <h4>/../../..</h4>
                                <p className="address">
                                    {resumeData.name}<br/>
                                    {resumeData.city}<br/>
                                    {resumeData.keys}<br/>
                                </p>
                            </div>
                            <div className="widget widget_tweets">
                                <img src={logo} className="App-logo" alt="logo"/>
                            </div>
                        </aside>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}